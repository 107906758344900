import { Flex, Text } from "@chakra-ui/react"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Splash({ image }) {
  return (
    <Flex
      bgColor="brand.100"
      w="100vw"
      h="100vh"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        margin="auto"
      >
        <GatsbyImage image={image} alt="Logo"></GatsbyImage>
        <Text color="brand.200" fontSize="2em">
          Coming soon
        </Text>
      </Flex>
      <Text color="brand.300" textAlign="center" marginBottom="auto">
        Can't wait? <br />
        Find out more: info@orbitwell.com
      </Text>
    </Flex>
  )
}
