import React from "react"
import Layout from "../Layout"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Splash from "../components/Splash"

export default function Home({ data }) {
  return (
    <Layout>
      <Splash image={getImage(data.file)}></Splash>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery($width: Int = 100, $width1: Int = 500) {
    file {
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: $width }
          placeholder: NONE
          width: $width1
        )
      }
    }
  }
`
