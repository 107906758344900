// 1. Import the extendTheme function
import React from "react"
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import { Helmet } from "react-helmet"

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {
    100: "#3E67AF",
    200: "#84E0BE",
    300: "#F4F2E9",
    400: "#707070",
  },
}

const theme = extendTheme({ colors })

// 3. Pass the `theme` prop to the `ChakraProvider`
export default function Layout({ children, meta }) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{meta?.title || "Orbit Well"}</title>
        <meta
          name="description"
          content={meta?.description || "Orbit Well"}
        ></meta>
        <link rel="canonical" href="https://orbitwell.com" />
      </Helmet>
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </>
  )
}
